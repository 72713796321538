import {
    UPDATE_REQUESTER,
    UPDATE_LOCATION,
    UPDATE_REASON,
    UNABLE_TO_READ_BARCODE,
    HELP_REQUEST_SUBMITTED_SUCCESS,
    HELP_REQUEST_SUBMITTED_FAILURE
} from "../action/CreateNotificationAction";
import {EMPTY, getNotificationFormat} from "../../utils/Utils";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {NOTIFICATION_STRINGS} from "../../../constants/Strings";
import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {DataHandler} from "@amzn/dolphin-web-framework";

export const CreateNotificationReducer = (state, action) => {
    switch (action.type) {
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                notification: undefined,
                isLoading: true
            }
        case UPDATE_REASON:
            return {
                ...state,
                reason: action.data,
                notification: undefined,
                isLoading: false
            }
        case UPDATE_REQUESTER:
            return {
                ...state,
                requester: action.data,
                notification: undefined,
                isLoading: false
            }
        case UPDATE_LOCATION:
            return {
                ...state,
                location: action.data,
                notification: undefined,
                isLoading: false
            }
        case HELP_REQUEST_SUBMITTED_SUCCESS:
            return {
                ...state,
                reason: null,
                requester: DataHandler.getUserEmployeeLogin(),
                location: EMPTY,
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, action.data),
                isLoading: false
            };
        case HELP_REQUEST_SUBMITTED_FAILURE:
            return {
                ...state,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRINGS.HELP_REQUEST_SUBMIT_FAILURE),
                isLoading: false
            };
        case UNABLE_TO_READ_BARCODE:
            return {
                ...state,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRINGS.UNABLE_TO_READ_BARCODE),
                isLoading: false
            }
        default:
            return state;
    }
}