import axios from "axios";
// import {DolphinAPIClientProvider} from "@amzn/dolphin-web-framework";
import {getNotificationServiceBaseURL, getDolphinAuthBaseURL, getDolphinAuthAPIKey} from "./BaseUrlUtils";

// export function getNotificationServiceClient() {
//     DolphinAPIClientProvider.generateDolphinAPIClient(getNotificationServiceBaseURL());
//     return DolphinAPIClientProvider.dolphinAPIClient;
// }

export function getNotificationServiceClient() {
    const dolphinAPIClient = axios.create({
        baseURL: getNotificationServiceBaseURL(),
        timeout: 20000
    });

    return dolphinAPIClient;
}

export function getDolphinAuthClient() {
    const dolphinAPIClient = axios.create({
        baseURL: getDolphinAuthBaseURL(),
        timeout: 20000,
        headers: {'X-API-Key': getDolphinAuthAPIKey()}
    });

    return dolphinAPIClient;
}

export async function axiosGET(path, params) {
    try {
        const response = await getNotificationServiceClient().get(path, params);
        return response;
    } catch(error) {
        console.error("GET call to path->",path, " errored out: ", error)
        throw error;
    }
}

export async function axiosPOST(path, requestData) {
    try {
        const response = await getNotificationServiceClient().post(path, requestData);
        return response;
    } catch(error) {
        console.error("GET call to path->",path, " errored out: ", error)
        throw error;
    }
}

export async function axiosDolphinAuthGET(path, params) {
    try {
        const response = await getDolphinAuthClient().get(path, params);
        return response;
    } catch(error) {
        console.error("GET call to path->",path, " errored out: ", error)
        throw error;
    }
}