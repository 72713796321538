import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {DISPLAY_NOTIFICATION, ADD_NOTIFICATION} from "../action/ListNotificationAction";


export const ListNotificationReducer = (state, action) => {
    switch (action.type) {
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                isLoading: true
            }
        case DISPLAY_NOTIFICATION:
            return {
                ...state,
                data: action.data,
                isLoading: false
            }
        case ADD_NOTIFICATION:
            return {
                ...state,
                data: [action.data, ...state.data],
                isLoading: false
            }
        default:
            return state;
    }
}