export const EMPTY = '';

export const isNonNull = (obj) => {
    return obj !== null && obj !== undefined
}

export const isNonEmpty = (obj) => {
    return isNonNull(obj) && obj !== EMPTY
}

export const getAliasFromUsername = (username) => {
    // If username is email then return alias part, else return complete username.
    if (username.includes('@')) {
        return username.split('@')[0];
    }
    return username;
}

export const getNotificationFormat = (type, message) => {
    return {
        type: type,
        message: message
    }
}

export const getFormattedString = (props, text) => {
    if(isNonEmpty(text)) {
        return props.intl.formatMessage({id: text})
    }
    return EMPTY
}

export const getLocation = () => {
    const params = new URLSearchParams(window.location.search);
    const redirectDataEncoded = params.get("redirectData") || EMPTY;
    try {
        const parsedRedirectData = JSON.parse(redirectDataEncoded);
        if (isNonNull(parsedRedirectData) && isNonNull(parsedRedirectData.data) && isNonNull(parsedRedirectData.data.location)) {
            return parsedRedirectData.data.location;
        }
    } catch (error) {
        console.error('Error parsing redirectData:', error);
    }
    return EMPTY;
}

export const getRedirectFromModule = () => {
    const params = new URLSearchParams(window.location.search);
    try {
        const redirectData = JSON.parse(params.get("redirectData"));
        if (isNonNull(redirectData)) {
            return redirectData.redirectFromModule;
        }
    } catch (e) {
        console.error("Error occurred during parsing redirectFromModule: ", e);
    }

    return EMPTY;
}