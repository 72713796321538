import Column from "@amzn/meridian/column";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import Row from '@amzn/meridian/row';
import React from 'react';
import comingSoon from "../../../images/coming_soon.png";

const renderHeader = () => {
    return (
        <HeaderView
            primaryTitle={{title:"Coming soon", size: "Large", type: "Bold"}}
            secondaryTitle={{title: "Insights on your performance", size:"Medium", type:"simple"}}
        />
    );
};

const renderComingSoon = () => {
    return (
        <Row spacingInset="medium" width='100%' alignmentHorizontal="center">
                <img alt={"coming soon"} src={comingSoon} width={"80%"}/>
        </Row>
    )
}

const HistoricSummaryView = () => {
    return(
        <Column spacingInset="200">
            {renderHeader()}
            {renderComingSoon()}
        </Column>
    )
}

export default HistoricSummaryView;