import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import {Route, Switch} from "react-router-dom";
import {getInsightRoutingPath, ROUTING} from "../../constants/Routing";
import SummaryView from "./summary/SummaryView";
import HistoricSummaryView from "./summary/HistoricSummaryView";

class Insight extends Component {
    render () {
        return (
            <Column height={"100%"}>
                <Switch>
                    <Route path={getInsightRoutingPath(ROUTING.SUMMARY)} component={SummaryView} />
                    <Route path={getInsightRoutingPath(ROUTING.HISTORICAL_SUMMARY)} component={HistoricSummaryView} />
                </Switch>
            </Column>
        )
    }
}

export default Insight;