import React, {useContext, useState} from "react";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import {CreateNotificationContext} from "../context/CreateNotificationContext";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import {STRING_IDS} from "../../../constants/Strings";
import {ROUTING} from "../../../constants/Routing";
import {UPDATE_REQUESTER} from "../action/CreateNotificationAction";
import {getFormattedString} from "../../utils/Utils";

const EditAliasViewTextInput = (props) => {

    const [alias, setAlias] = useState('');
    const {CreateNotificationDispatch} = useContext(CreateNotificationContext);

    const init = {
        primaryTitle: {
            title: getFormattedString(props, STRING_IDS.WHO_NEEDS_HELP),
            size: "Large",
            type: "Simple"
        },
        secondaryTitle: {
            title: getFormattedString(props, STRING_IDS.ENTER_THEIR_ALIAS),
            size: "Large",
            type: "Bold"
        }
    }

    const renderHeader = () => {
        return (
            <Column>
                <HeaderView primaryTitle={init.primaryTitle} secondaryTitle={init.secondaryTitle} />
            </Column>
        )
    }

    const renderInput = () => {
        return (
            <Column>
                <Input value={alias} onChange={setAlias} type="text" placeholder="Alias" width={"100%"}/>
            </Column>
        );
    }

    const handleSubmitButtonClick = () => {
        CreateNotificationDispatch({type: UPDATE_REQUESTER, data: alias});
        props.history.push(ROUTING.CREATE_NOTIFICATION);
    }

    const renderSubmitButton = () => {
        return (
            <Column>
                <Button size="xlarge" disabled={alias === ''} onClick={handleSubmitButtonClick}>{getFormattedString(props, STRING_IDS.SUBMIT)}</Button>
            </Column>
        )
    }

    return (
        <Column spacingInset={"medium"} height={"100%"}>
            {renderHeader()}
            {renderInput()}
            {renderSubmitButton()}
        </Column>
    )
}

export default injectIntl(EditAliasViewTextInput);