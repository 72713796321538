import React, {useEffect, useState} from 'react';
import Column from "@amzn/meridian/column";
import { css } from "emotion";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Card from "@amzn/meridian/card";
import StowSummaryView from "./StowSummaryView";
import {getRedirectFromModule} from "../../utils/Utils";
import {getConsentStatus, updateConsentStatus, isRedirectFromModuleValid, exitModule} from "./NetworkUtils";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {CONSENT_STATUS, REDIRECT_FROM_MODULE} from "./Constants";

const SummaryView = (props) => {

    const redirectFromModule = getRedirectFromModule();
    const [consentStatus, setConsentStatus] = useState(null);
    const [isUserSelectedToAskLater, setIsUserSelectedToAskLater] = useState(false);

    const centerAlignText = css({
        textAlign: "center"
    });

    const linkText = css({
        padding: "0px",
        color: "#077398",
        textDecoration: "underline",
        textDecorationColor: "#077398"
    });

    useEffect(() => {
        if (!isRedirectFromModuleValid(redirectFromModule)) {
            exitModule();
        } else {
            getConsentStatus(redirectFromModule, setConsentStatus);
        }
    }, []);

    const onAcceptConsentButtonClick = () => {
        updateConsentStatus(redirectFromModule, true, setConsentStatus);
    }

    const onAskMeLaterButtonClick = () => {
        setIsUserSelectedToAskLater(true);
    }

    const onDeclineConsentButtonClick = () => {
        updateConsentStatus(redirectFromModule, false, setConsentStatus);
    }

    const renderConsentOptions = () => {
        return (
            <Card width={"100%"} spacingInset={"200"}>
                <Column>
                    <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                        <Box>
                            <Text className={centerAlignText}>{`Show me my shift summary`}</Text>
                        </Box>
                    </Row>
                    <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>
                        <Button onClick={onAcceptConsentButtonClick}>Accept</Button>
                        <Button onClick={onAskMeLaterButtonClick} type={"secondary"}>Ask me later</Button>
                    </Row>
                    <Row alignmentHorizontal={"end"} width={"100%"}>
                        <span onClick={onDeclineConsentButtonClick}><Text type={"b200"} className={linkText}>{`Don't ask me again`}</Text></span>
                    </Row>
                </Column>
            </Card>
        )
    }

    const renderSummaryView = () => {

        // Display nothing until consent status is not fetched.
        if (!consentStatus) {
            return <Loader/>;
        }

        // Exit from module if user asked to remind him/her later.
        if (isUserSelectedToAskLater) {
            exitModule();
        }

        if (consentStatus === CONSENT_STATUS.NOT_FOUND) {
            return (
                    renderConsentOptions()
            );
        } else if (consentStatus === CONSENT_STATUS.CONSENTED) {
             if (redirectFromModule === REDIRECT_FROM_MODULE.STOW) {
                return (
                    <StowSummaryView {...props} />
                );
            }
        }

        // Exit from module if consent status is DECLINED or anything else.
        exitModule();
    }

    return (
        <Column width={"100%"} height={"100%"} spacingInset={"small"} alignmentVertical={"center"}>
            {renderSummaryView()}
        </Column>
    )
}

export default SummaryView;