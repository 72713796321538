import React, {createContext, useReducer} from 'react';
import {CreateNotificationReducer} from "../reducer/CreateNotificationReducer";
import {getLocation} from "../../utils/Utils";
import {ROUTING} from "../../../constants/Routing";

export const CreateNotificationContext = createContext();

const getInitialLocation = () => {
    return getLocation().replace(ROUTING.CREATE_NOTIFICATION, '');
}

export const model = {
    isLoading: false,
    notification: undefined,
    reason: null,
    requester: null,
    location: getInitialLocation()
}

const CreateNotificationContextProvider = (props) => {

    const [CreateNotificationModel, CreateNotificationDispatch] = useReducer(CreateNotificationReducer, model);
    return (
        <CreateNotificationContext.Provider value={{CreateNotificationModel, CreateNotificationDispatch}}>
            {props.children}
        </CreateNotificationContext.Provider>
    )
}

export default CreateNotificationContextProvider;