import React, {useEffect, useState } from 'react';
import {useLocation } from 'react-router-dom';
import {injectIntl} from "react-intl";
import {TopNavigationView} from "@amzn/dolphin-uiweb-framework";
import {STRING_IDS} from "../../constants/Strings";
import {getInsightRoutingPathNoTailSlash, ROUTING} from "../../constants/Routing";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getRedirectFromModule, isNonEmpty} from "../utils/Utils";
import {getFormattedString} from "../utils/Utils";

const AppNavigationView = (props) => {
    const [titleText, setTitleText] = useState(STRING_IDS.ASK_FOR_HELP);

    const location = useLocation();
  
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        // If we're navigating back from the summary page, exit the module.
        if (location.pathname === getInsightRoutingPathNoTailSlash(ROUTING.SUMMARY)) {
            NativeMeshInteractor.exitModule();
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
 
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [location]);

    useEffect(() => {
        const path = window.location.hash.substring(1);
        if (path === getInsightRoutingPathNoTailSlash(ROUTING.SUMMARY) ||
            path === getInsightRoutingPathNoTailSlash(ROUTING.HISTORICAL_SUMMARY)) {
            setTitleText(STRING_IDS.SUMMARY);
        } else {
            setTitleText(STRING_IDS.ASK_FOR_HELP);
        }
    });

    const handleBackButtonPress = () => {
        const location = window.location;
        const path = location.hash.substring(1);

        if (path === getInsightRoutingPathNoTailSlash(ROUTING.SUMMARY)){
            NativeMeshInteractor.exitModule();
        } else if(path === ROUTING.CREATE_NOTIFICATION ||
            path === ROUTING.LIST_NOTIFICATION ) {
            // If redirected from another PWA, return to previous PWA page, else exit to native.
            if (isNonEmpty(getRedirectFromModule())) {
                props.history.goBack();
            } else {
                NativeMeshInteractor.exitModule();
            }
        } else {
            props.history.goBack();
        }
    }

    return (
        <TopNavigationView
            title={getFormattedString(props, titleText)}
            onMenuButtonClicked={handleBackButtonPress}
            optionsMenu={[]}/>
    )
}

export default injectIntl(AppNavigationView);