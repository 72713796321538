import React, {Component} from 'react';
import LandingView from "./view/LandingView";

class Landing extends Component {
    render() {
        return (
            <LandingView history={this.props.history} />
        )
    }
}

export default Landing;