export const REDIRECT_FROM_MODULE = {
    STOW: "STOW",
    INDUCT: "INDUCT"
}

export const CONSENT_STATUS = {
    NOT_FOUND: "NOT_FOUND",
    CONSENTED: "CONSENTED",
    DECLINED: "DECLINED"
}

export const ANALYTICS_DESCRIPTION = {
    STOW_SUMMARY_METRIC: "STOW_SUMMARY_METRIC"
}