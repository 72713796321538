import {LMFSAccessor, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {getRegion, getStage} from "../../utils/network/BaseUrlUtils";
import {REDIRECT_FROM_MODULE} from "./Constants";

const MAX_SHIFT_HOURS = 10;

async function getConsentStatus(module, setConsentStatus) {
    const data = await LMFSAccessor.fetchUpdateConsentStatus(getStage(), getRegion(), LMFSAccessor.CONSENT_REQUEST_TYPES.FETCH,
        getConsentType(module), false);
    if (data && data.result) {
        setConsentStatus(data.result);
    }
}

async function updateConsentStatus(module, isConsented, setConsentStatus) {
    const data = await LMFSAccessor.fetchUpdateConsentStatus(getStage(), getRegion(), LMFSAccessor.CONSENT_REQUEST_TYPES.UPDATE,
        getConsentType(module), isConsented);
    if (data && data.result) {
        setConsentStatus(data.result);
    }
}

async function getStowMetrics(setData) {
    const data = await LMFSAccessor.fetchStowMetrics(getStage(), getRegion(), LMFSAccessor.METRIC_QUERY_TYPE.SUMMARY, getShiftStartTime());
    if (data) {
        setData(data);
    }
}

function isRedirectFromModuleValid(module) {
    return module === REDIRECT_FROM_MODULE.STOW;
}

function getConsentType(module) {
    if (module === REDIRECT_FROM_MODULE.STOW) {
        return LMFSAccessor.CONSENT_TYPE.STOW_SUMMARY;
    }
    return null;
}

function getShiftStartTime() {
    const shiftStartTime = new Date();
    shiftStartTime.setHours(shiftStartTime.getHours() - MAX_SHIFT_HOURS);
    return shiftStartTime.getTime();
}

const exitModule = () => {
    NativeMeshInteractor.exitModule();
}

function getNumericalDisplayValueFromData(value) {
    if (typeof value === 'number') {
        return Math.round(value);
    }
    return 'N/A';
}

export {
    getConsentStatus,
    updateConsentStatus,
    isRedirectFromModuleValid,
    getStowMetrics,
    exitModule,
    getNumericalDisplayValueFromData
}