import React, {Fragment, useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {HeaderView, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {STRING_IDS} from "../../../constants/Strings";
import BadgeImage from "../../../images/ic_badge.png";
import {CreateNotificationContext} from "../context/CreateNotificationContext";
import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {getAssociateUsername} from "../action/CreateNotificationAction";
import {getFormattedString, isNonNull} from "../../utils/Utils";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import Button from "@amzn/meridian/button";
import {getNotificationRoutingPath, ROUTING} from "../../../constants/Routing";

const EditAliasViewBadgeScan = (props) => {

    const {CreateNotificationModel, CreateNotificationDispatch} = useContext(CreateNotificationContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);

    useEffect(() => {
        if (isNonNull(CreateNotificationModel.notification)) {
            setNotification(CreateNotificationModel.notification);
        }
    }, [CreateNotificationModel]);

    const init = {
        primaryTitle: {
            title: getFormattedString(props, STRING_IDS.WHO_NEEDS_HELP),
            size: "Large",
            type: "Simple"
        },
        secondaryTitle: {
            title: getFormattedString(props, STRING_IDS.SCAN_THEIR_BADGE),
            size: "Large",
            type: "Bold"
        }
    }

    const onBarcodeScan = (input) => {
        CreateNotificationDispatch({type: COMMON_ACTIONS.LOADING});
        getAssociateUsername(input.trim(), CreateNotificationDispatch, props.history);
    }

    const renderHeader = () => {
        return (
            <Column>
                <HeaderView primaryTitle={init.primaryTitle} secondaryTitle={init.secondaryTitle} />
            </Column>
        );
    }

    const renderBadgeImage = () => {
        return (
            <Column alignmentHorizontal={"center"}>
                <img src={BadgeImage} alt={init.secondaryTitle.title} width={"40%"} height={"fit-content"}/>
            </Column>
        );
    }

    const handleDontHaveBadgeButtonClick = () => {
        props.history.push(getNotificationRoutingPath(ROUTING.EDIT_ALIAS_TEXT_INPUT));
    }

    const renderNoBadgeButton = () => {
        return (
            <Column width="100%" alignmentVertical="bottom">
                <Button size={"medium"} type={"link"} onClick={handleDontHaveBadgeButtonClick}>
                    {getFormattedString(props, STRING_IDS.DONT_HAVE_BADGE)}
                </Button>
            </Column>
        )
    }

    const renderEditAliasBadgeScan = () => {
        return (
            <Fragment>
                {renderHeader()}
                {renderBadgeImage()}
                {renderNoBadgeButton()}
                <ScanWithCodeView onSubmit={onBarcodeScan} focusLock={true} ariaLabel={props.ariaLabel}/>
            </Fragment>
        )
    }

    return (
        <Column spacingInset={"medium"} height={"100%"}>
            {CreateNotificationModel.isLoading ? <Loader/> : renderEditAliasBadgeScan()}
        </Column>
    )
}

export default injectIntl(EditAliasViewBadgeScan);