import React from "react";
import Card, { CardHeader } from "@amzn/meridian/card";
import {TextView} from "@amzn/dolphin-uiweb-framework";
import Badge from "@amzn/meridian/badge";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import moment from "moment";

const NotificationItemView = (props) => {

    const {createdAt, message} = props.data;

    const calculateTimeSince = () => {
        console.log("createdAt -> ", createdAt)
        const createdAtDate = new Date(createdAt);
        return moment(createdAtDate).fromNow();
    }

    return (
        <Card onClick={() => {}}
        >
            <CardHeader>
                <TextView title={message.title} size={"Medium"} type={"Bold"}/>
            </CardHeader>
            <Row widths={["grid-8", "grid-4"]} spacingInset={"none"}>
                <Column alignmentHorizontal={"start"} spacingInset={"none"}>
                    <TextView title={message.messageBody} size={"Small"} type={"Simple"}/>
                </Column>
                <Column alignmentHorizontal={"end"} spacingInset={"none"}>
                    <Badge value={calculateTimeSince()} type={"neutral"}/>
                </Column>
            </Row>
        </Card>
    )
}

export default NotificationItemView;