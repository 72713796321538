import React, {createContext, useReducer} from 'react'
import {CLEAR_NOTIFICATION, NotificationReducer, SET_NOTIFICATION} from "../reducer/NotificationReducer";

export const NotificationContext = createContext();

export const model = {
    type: '',
    message: ''
}

const NotificationContextProvider = (props) => {
    const [notificationModel, notificationDispatch] = useReducer(NotificationReducer, model);

    const clearNotification = () => {
        notificationDispatch({type: CLEAR_NOTIFICATION});
    }

    const setNotification = (notification) => {
        notificationDispatch({
            type: SET_NOTIFICATION,
            data: notification
        });
    }

    return (
        <NotificationContext.Provider value={{
            notificationModel, notificationDispatch,
            notificationActions: {clearNotification, setNotification}
        }}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextProvider;
