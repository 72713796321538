import React, {Component} from "react";
import ListNotificationView from "./view/ListNotificationView";
import ListNotificationContextProvider from "./context/ListNotificationContext";

class ListNotification extends Component {
    render() {
        return (
            <ListNotificationContextProvider>
                <ListNotificationView />
            </ListNotificationContextProvider>
        )
    }
}

export default ListNotification;