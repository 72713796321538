import React, {Component} from 'react';
import NotificationView from "./view/NotificationView";


class Notification extends Component {
    render() {
        return (
            <NotificationView history = {this.props.history}/>
        )
    }
}

export default Notification;