import {EMPTY} from "../components/utils/Utils";

export const ROUTING = {
    LANDING: "/",
    NO_INTERNET: "/no-internet",
    CREATE_NOTIFICATION: "/create-notification",
    LIST_NOTIFICATION: "/list-notification",
    NEW: "/new",
    EDIT_ALIAS_BADGE_SCAN: "/edit-alias-badge-scan",
    EDIT_ALIAS_TEXT_INPUT: "/edit-alias-text-input",
    EDIT_LOCATION: "/edit-location",
    INSIGHT: "/insight",
    SUMMARY: "/summary",
    HISTORICAL_SUMMARY: "/historical-summary",

    // Safety Concern routes
    SAFETY_CONCERN: "/safety-concern",
    RECORD: "/record",
    REVIEW: "/review",
    HAZARD_TYPE: "/hazard-type",
    COMPLETED: "/completed",
}

const FORWARD_SLASH_REGEX = /\/$/

export const getNotificationRoutingPath = (path) => {
    return ROUTING.CREATE_NOTIFICATION + path;
}

export const getInsightRoutingPath = (path) => {
    if(path.charAt(path.length - 1) === "/"){
        return ROUTING.INSIGHT + path;
    }
    return ROUTING.INSIGHT + path + "/";
}

export const getInsightRoutingPathNoTailSlash = (path) => {
    const routingPath = ROUTING.INSIGHT + path;
    return routingPath.replace(FORWARD_SLASH_REGEX, EMPTY)
}

/**
 * Get routing path for create safety concern components
 * @param path
 * @return {string}
 */
export const getCreateSafetyConcernRoutingPath = (path) => {
    return ROUTING.SAFETY_CONCERN + path;
}