import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import {addLocaleData, IntlProvider} from "react-intl";
import './index.css';
import App from "./components/app";
import * as serviceWorker from './serviceWorker';
import {getLoggerUrl, getStage} from "./components/utils/network/BaseUrlUtils";
import {Logger, DataHandler} from "@amzn/dolphin-web-framework";
import {getLocale, getMessages, getDirection} from "./components/handler/LocaleHandler";
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import hi from 'react-intl/locale-data/hi'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ar from 'react-intl/locale-data/ar'
import nl from 'react-intl/locale-data/nl'
import tr from 'react-intl/locale-data/tr'

addLocaleData(de);
addLocaleData(en);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);

DataHandler.parseSearchParams();
Logger.setLogger(getLoggerUrl(), getStage());
document.getElementsByTagName(`html`)[0].setAttribute("dir", getDirection());

ReactDOM.render((
    <IntlProvider locale={getLocale()} messages={getMessages()}>
        <HashRouter>
            <App/>
        </HashRouter>
    </IntlProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (serviceWorkerRegistration) => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.hash = "";
                    window.location.reload();
                }
            });
        }
    }
});