import React, {Component} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {NoInternetView, HelpRequestNotificationView} from "@amzn/dolphin-uiweb-framework";
import noInternetImage from "../images/no_internet.png";
import {FeatureManager, MeshClient} from "@amzn/dolphin-web-framework";
import {getStage, getRegion} from "./utils/network/BaseUrlUtils";
import Column from "@amzn/meridian/column";
import AppNavigation from "./app-navigation/AppNavigation";
import Notification from "./notification/Notification";
import NotificationContextProvider from "./notification/context/NotificationContext";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {ROUTING} from "../constants/Routing";
import CreateNotification from "./create-notification/CreateNotification";
import ListNotification from "./list-notification/ListNotification";
import Insight from "./insight/InsightContainer";
import Landing from "./landing/Landing";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: true};
    }

    async componentWillMount() {
        console.log("Starting the websocket connection.....");
        await MeshClient.connectWebSocket();
        console.log("Websocket connected!");
        await FeatureManager.getFeatures(getStage(), getRegion());
        this.setState({loading: false});
    }

    componentWillUnmount() {
        MeshClient.closeWebSocketConnection();
    }

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact path={ROUTING.NO_INTERNET}>
                        <NoInternetView
                            title={"no_internet"}
                            openSettingsButtonText={"device_setting"}
                            noInternetImage={noInternetImage}
                            history={this.props.history}/>
                    </Route>
                </Switch>
                {this.state.loading ? <Loader /> :
                    <Column height="100%" spacing="none" width="100%">
                        <AppNavigation history={this.props.history}/>
                        <HelpRequestNotificationView />
                        <NotificationContextProvider>
                            <Notification />
                            <Column height="100%" spacing="none" heights="fill" overflowY={"auto"}>
                                <Switch>
                                    <Route exact path={ROUTING.LANDING} component={Landing}/>
                                    <Route path={ROUTING.CREATE_NOTIFICATION} component={CreateNotification} />
                                    <Route path={ROUTING.LIST_NOTIFICATION} component={ListNotification} />
                                    <Route path={ROUTING.INSIGHT} component={Insight} />
                                </Switch>
                            </Column>
                        </NotificationContextProvider>
                    </Column>}

            </React.Fragment>
        );
    }
}

export default withRouter(App);