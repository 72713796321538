import React, {useEffect} from 'react';
import Column from "@amzn/meridian/column";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {ROUTING} from "../../../constants/Routing";
import {getLocation} from "../../utils/Utils";

const LandingView = (props) => {

    useEffect(() => {
        if (getLocation().includes(ROUTING.CREATE_NOTIFICATION)) {
            props.history.push(ROUTING.CREATE_NOTIFICATION);
        } else {
            props.history.push(ROUTING.LIST_NOTIFICATION);
        }
    }, []);

    return (
        <Column width={"100%"} height={"100%"} spacingInset={"small"}>
            <Loader />
        </Column>
    )
}

export default LandingView;