import {getUsernameForBarcode, submitHelpRequest} from "../network/CreateNotificationNetwork";
import {DataHandler} from "@amzn/dolphin-web-framework";
import {ROUTING} from "../../../constants/Routing";
import { v4 as uuidv4 } from 'uuid';
import {
    CLIENT_ID,
    CREATE_HELP_REQUEST_DATA,
    SEVERITY,
    SLA,
    COMMON_STRINGS,
    REASON,
    STRING_IDS
} from "../../../constants/Strings";
import {getFormattedString} from "../../utils/Utils";

export const UPDATE_REASON = "UPDATE_REASON";
export const UPDATE_REQUESTER = "UPDATE_REQUESTER";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UNABLE_TO_READ_BARCODE = "UNABLE_TO_READ_BARCODE";
export const HELP_REQUEST_SUBMITTED_SUCCESS = "HELP_REQUEST_SUBMITTED_SUCCESS";
export const HELP_REQUEST_SUBMITTED_FAILURE = "HELP_REQUEST_SUBMITTED_FAILURE";

export const getAssociateUsername = (barcode, dispatch, history) => {
    getUsernameForBarcode(barcode).then(response => {
        if (response.status === 200) {
            const username = response.data.username;
            dispatch({type: UPDATE_REQUESTER, data: username});
            history.push(ROUTING.CREATE_NOTIFICATION)
        } else {
            dispatch({type: UNABLE_TO_READ_BARCODE})
        }
    }).catch(error => {
        console.error(error);
        dispatch({type: UNABLE_TO_READ_BARCODE})
    });
}

export const createHelpRequest = (dispatch, reasonId, stationCode, creator, requester, location, props) => {
    const creationTime = new Date().getTime();
    const notificationExpiry = new Date(creationTime + SLA.THIRTY_MINS).getTime();



    console.log("creationTime -> ", creationTime)
    console.log("notificationExpiry -> ", notificationExpiry);
    const requestData = {
        taskId: `${COMMON_STRINGS.DOLPHIN}_${COMMON_STRINGS.HELP_REQUEST}_${uuidv4()}`,
        name: reasonId,
        businessUnit: CREATE_HELP_REQUEST_DATA.AMZL_STATIONS,
        clientId: CLIENT_ID.SCC,
        callToActionEnabled: true,
        stationCode: stationCode,
        creator: creator,
        requester: requester,
        url: COMMON_STRINGS.WORK_ITEMS_VISIBILITY,
        severity: SEVERITY.HIGH,
        expirationTimeEpochMillis: notificationExpiry,
        category: `${COMMON_STRINGS.UTR}_${location}_${stationCode}`,
        location: location,
        notificationExpiry: notificationExpiry,
        creationTime: creationTime,
        taskType: COMMON_STRINGS.HELP_REQUEST,
        sla: 30,
        ackMessage: getAckMessage(reasonId, props),
        message: getSccMessage(REASON[reasonId].scc_label, location, props)
    }

    submitHelpRequest(requestData).then(response => {
        if (response.status === 200) {
            dispatch({type: HELP_REQUEST_SUBMITTED_SUCCESS, data: getSuccessNotificationMessage(reasonId, props)});
        } else {
            dispatch({type: HELP_REQUEST_SUBMITTED_FAILURE});
        }
    }).catch(error => {
        console.error(error);
        dispatch({type: HELP_REQUEST_SUBMITTED_FAILURE});
    });
}

const getSuccessNotificationMessage = (reasonId, props) => {
    return getFormattedString(props, REASON.AMZLStations_Help_Request_Injury.id === reasonId ?
        getSuccessRestMessage(props) : STRING_IDS.REQUEST_SENT_CONTINUE_WORKING);
}

const getSuccessRestMessage = (props) => {
    return getFormattedString(props, DataHandler.getCountry() === "US" ? STRING_IDS.REQUEST_SENT_REST_LONG : STRING_IDS.REQUEST_SENT_REST);
}

const getAckMessage = (reasonId, props) => {
    const messageBody = getFormattedString(props, REASON.AMZLStations_Help_Request_Injury.id === reasonId ?
        STRING_IDS.REQUEST_SENT_REST : STRING_IDS.REQUEST_SENT_CONTINUE_WORKING);
    return (
        {
            title: getFormattedString(props, STRING_IDS.HELP_IS_ON_THE_WAY),
            messageBody: messageBody
        }
    )
}

const getSccMessage = (reasonLabel, location, props) => {
    if (reasonLabel === REASON.AMZLStations_Help_Request_Injury.label) {
        const messageBody = getFormattedString(props, STRING_IDS.SCC_MESSAGE_CARE);
        const messageWithLocation = messageBody.replace("{location}", location);
        return ({
            title:  getFormattedString(props, reasonLabel),
            messageBody: messageWithLocation
        })
    }
    const messageBody = getFormattedString(props, STRING_IDS.SCC_MESSAGE_SUPPORT);
    const messageWithLocation = messageBody.replace("{location}", location);
    return ({
        title:  getFormattedString(props, reasonLabel),
        messageBody: messageWithLocation
    });
}