import React, {Fragment, useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import {TextView} from "@amzn/dolphin-uiweb-framework";
import {REASON, STRING_IDS} from "../../../constants/Strings";
import styles from "./styles.module.css";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Select, {SelectOption} from "@amzn/meridian/select";
import Button from "@amzn/meridian/button"
import Text from "@amzn/meridian/text";
import Alert from "@amzn/meridian/alert";
import {DataHandler} from "@amzn/dolphin-web-framework";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {getNotificationRoutingPath, ROUTING} from "../../../constants/Routing";
import {CreateNotificationContext} from "../context/CreateNotificationContext";
import {UPDATE_REQUESTER, UPDATE_REASON, createHelpRequest} from "../action/CreateNotificationAction";
import {getAliasFromUsername, getFormattedString, isNonNull} from "../../utils/Utils";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {COMMON_ACTIONS} from "../../../constants/ActionType";

const CreateNotificationView = (props) => {

    const username = DataHandler.getUserEmployeeLogin();
    const stationCode = DataHandler.getStationCode();

    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {CreateNotificationModel, CreateNotificationDispatch} = useContext(CreateNotificationContext);

    useEffect(() => {
        if (isNonNull(CreateNotificationModel.notification)) {
            setNotification(CreateNotificationModel.notification);
        }
    }, [CreateNotificationModel]);

    useEffect(() => {
        if (username && CreateNotificationModel.requester == null) {
            CreateNotificationDispatch({type: UPDATE_REQUESTER, data: username})
        }
    }, []);

    const renderHeader = () => {
        return (
            <Column alignmentHorizontal={"center"} spacingInset={"small"}>
                <TextView title={getFormattedString(props, STRING_IDS.NEED_HELP_NOW)} size={"Large"} type={"Bold"} />
            </Column>
        )
    }

    const onRenderSelect = (value) => {
        CreateNotificationDispatch({type: UPDATE_REASON, data: value});
    }

    const renderSelect = () => {
        return (
            <Column>
                <Select
                    value={CreateNotificationModel.reason}
                    onChange={onRenderSelect}
                    placeholder={getFormattedString(props, STRING_IDS.SELECT_REASON)}
                    width={"100%"}
                >
                    <SelectOption value={REASON.AMZLStations_Help_Request_Team_Lift.id} label={getFormattedString(props, REASON.AMZLStations_Help_Request_Team_Lift.label)} />
                    <SelectOption value={REASON.AMZLStations_Help_Request_Spill.id} label={getFormattedString(props, REASON.AMZLStations_Help_Request_Spill.label)} />
                    <SelectOption value={REASON.AMZLStations_Help_Request_Injury.id} label={getFormattedString(props, REASON.AMZLStations_Help_Request_Injury.label)} />
                </Select>
            </Column>
        )
    }

    const renderTeamLiftAlert = () => {
        if (CreateNotificationModel.reason === REASON.AMZLStations_Help_Request_Team_Lift.id) {
            return (
                <Alert type={"warning"}>{getFormattedString(props, STRING_IDS.TEAM_LIFT_WARNING)}</Alert>
            )
        }
        return null;
    }

    const onNotMeButtonClick = () => {
        props.history.push(getNotificationRoutingPath(ROUTING.EDIT_ALIAS_BADGE_SCAN));
    }

    const renderAssociateAlias = () => {
        return (
            <Column>
                <Row widths={["grid-8", "grid-4"]} spacingInset={"none"} alignmentVertical={"top"}>
                    <Text><span className={styles.bold}>{CreateNotificationModel.requester}</span><br/><span className={styles.helperText}>{getFormattedString(props, STRING_IDS.WHO_NEEDS_HELP)}</span></Text>
                    <Column alignmentHorizontal={"end"} spacingInset={"none"} alignmentVertical={"top"}>
                        <span onClick={onNotMeButtonClick}><Text className={styles.button}>{getFormattedString(props, STRING_IDS.NOT_ME)}</Text></span>
                    </Column>
                </Row>
            </Column>
        )
    }

    const onEditLocationClick = () => {
        props.history.push(getNotificationRoutingPath(ROUTING.EDIT_LOCATION));
    }

    const renderLocation = () => {
        return (
            <Column spacing={"none"}>
                <Row widths={["grid-8", "grid-4"]} spacingInset={"none"} alignmentVertical={"top"}>
                    <Text><span className={styles.bold}>{CreateNotificationModel.location}</span></Text>
                </Row>
                <Row widths={["grid-6", "grid-6"]} spacingInset={"none"}>
                    <Column alignmentHorizontal={"start"} spacingInset={"none"}>
                        <span onClick={onEditLocationClick}><Text className={styles.button}>{getFormattedString(props, STRING_IDS.SCAN_LOCATION)}</Text></span>
                    </Column>
                </Row>
                <Row widths={["grid-12"]} spacingInset={"none"}>
                    <Column alignmentHorizontal={"start"} spacingInset={"none"}>
                        <Text><span className={styles.helperText}>{getFormattedString(props, STRING_IDS.LOCATION_DESC)}</span></Text>
                    </Column>
                </Row>
            </Column>
        )
    }

    const isButtonDisabled = () => {
        if (!CreateNotificationModel.reason || !CreateNotificationModel.location) {
            return true;
        }
        return false;
    }

    const handleOnSendClick = () => {
        CreateNotificationDispatch({type: COMMON_ACTIONS.LOADING});
        console.log("CreateNotificationModel.reason -> ", CreateNotificationModel.reason);
        createHelpRequest(CreateNotificationDispatch, CreateNotificationModel.reason,
            stationCode, getAliasFromUsername(username),
            getAliasFromUsername(CreateNotificationModel.requester), CreateNotificationModel.location, props);
    }

    const renderSendButton = () => {
        return (
            <Column alignmentHorizontal={"center"} width="100%" alignmentVertical="bottom">
                <Button minWidth={"100%"} size={"large"} disabled={isButtonDisabled()} onClick={handleOnSendClick}>{getFormattedString(props, STRING_IDS.SEND)}</Button>
            </Column>

        );

    }

    console.log("in create-notification")

    const renderCreateNotification = () => {
        return (
            <Fragment>
                {renderHeader()}
                {renderSelect()}
                {renderTeamLiftAlert()}
                {renderAssociateAlias()}
                {renderLocation()}
                {renderSendButton()}
            </Fragment>
        )
    }

    return (
        <Column spacingInset={"medium"} height={"100%"} heights={["fit", "fit", "fit", "fit", "fill"]}>
            {CreateNotificationModel.isLoading ? <Loader/> : renderCreateNotification()}
        </Column>
    )
}

export default injectIntl(CreateNotificationView);