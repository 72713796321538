import {axiosGET} from "../../utils/network/NetworkUtils";
import {API_PATH} from "../../../constants/BaseUrls";
import {CLIENT_ID} from "../../../constants/Strings";

export const fetchNotificationsNetwork = async (alias) => {
    try {
        const params = {
            params: {
                clientId: CLIENT_ID.DOLPHIN,
                notificationRecipient: alias
            }
        }
        const response = await axiosGET(API_PATH.GET_NOTIFICATION, params);
        console.log("response from fetchNotificationsNetwork -> ", response);
        return response;
    } catch (error) {
        return error?.response;
    }
}