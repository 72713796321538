import React, {Fragment, useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import NotificationItemView from "./NotificationItemView";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading"
import {Loader} from "@amzn/dolphin-uiweb-framework";
import {ListNotificationContext} from "../context/ListNotificationContext";
import {ADD_NOTIFICATION, fetchNotifications} from "../action/ListNotificationAction";
import {DataHandler, MeshEvents} from "@amzn/dolphin-web-framework";
import {STRING_IDS} from "../../../constants/Strings";
import {getAliasFromUsername, getFormattedString} from "../../utils/Utils";

const ListNotificationView = (props) => {
    
    const username = DataHandler.getUserEmployeeLogin();

    const {listNotificationModel, listNotificationDispatch} = useContext(ListNotificationContext);

    const renderNotificationList = () => {
        if (listNotificationModel.data.length !== 0) {
            return listNotificationModel.data.map((notification) => {
                return (
                    <NotificationItemView data={notification} />
                )
            })
        }
        return (
            <Heading level={4} type="h200">
                {getFormattedString(props, STRING_IDS.YOU_HAVE_NO_NOTIFICATIONS)}
            </Heading>
        );

    }

    useEffect(() => {
        // Sending stationCode as alias for temp fix.
        fetchNotifications(listNotificationDispatch, getAliasFromUsername(username));
    }, []);

    const onMessage = (event) => {
        try {
            const eventData = JSON.parse(event.data);
            if(eventData.eventName && eventData.eventName === MeshEvents.MESH_EVENTS.HELP_REQUEST_NOTIFICATION_RECEIVED) {
                const payload = JSON.parse(eventData.payload);
                const notificationDataPayload = JSON.parse(payload.notificationData);
                listNotificationDispatch({type: ADD_NOTIFICATION, data: notificationDataPayload});
            }
        } catch (JSONParseException) {
            console.error("Enable to parse event : " + event.data);
        }
    }

    useEffect(() => {
        const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');
        dolphinBus.addEventListener('message', onMessage);

        return () => dolphinBus.removeEventListener('message', onMessage);
    }, []);

    const renderListNotificationView = () => {
        return (
            <Fragment>
                {renderNotificationList()}
                <Box height={"50px"}/>
            </Fragment>
        )
    }

    console.log("in ListNotificationView");

    return (
        <Column heights={"fit"} overflowY={"auto"}>
            {listNotificationModel.isLoading ? <Loader /> : renderListNotificationView()}
        </Column>
    )
}

export default injectIntl(ListNotificationView);