import {axiosDolphinAuthGET, axiosPOST} from "../../utils/network/NetworkUtils";
import {API_PATH} from "../../../constants/BaseUrls";

export const getUsernameForBarcode = async (barcode) => {
    try {
        const response = await axiosDolphinAuthGET(`${API_PATH.GET_USERNAME_FOR_BARCODE}/${barcode}`);
        return response;
    } catch (error) {
        console.error("error during the network call for getUsernameForBarcode: ", error);
    }
}

export const submitHelpRequest = async (requestData) => {
    try {
        const response = await axiosPOST(`${API_PATH.CREATE_HELP_REQUEST}`, requestData);
        return response;
    } catch (error) {
        console.error("error during the network call for getUsernameForBarcode: ", error);
    }
}