import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import {STRING_IDS} from "../../../constants/Strings";
import Column from "@amzn/meridian/column";
import {HeaderView, ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCodeImage from "../../../images/ic_qr_code.png";
import Button from "@amzn/meridian/button";
import {CreateNotificationContext} from "../context/CreateNotificationContext";
import {UPDATE_LOCATION} from "../action/CreateNotificationAction";
import {ROUTING} from "../../../constants/Routing";
import {getFormattedString} from "../../utils/Utils";

const EditLocationView = (props) => {

    const {CreateNotificationDispatch} = useContext(CreateNotificationContext);

    const init = {
        primaryTitle: {
            title: getFormattedString(props, STRING_IDS.SCAN_NEAREST_LOCATION_QR),
            size: "Large",
            type: "Bold"
        },
        secondaryTitle: {
            title: getFormattedString(props, STRING_IDS.SCAN_NEAREST_LOCATION_QR)
        }
    }

    const onQrCodeScan = (input) => {
        CreateNotificationDispatch({type: UPDATE_LOCATION, data: input.trim()});
        props.history.push(ROUTING.CREATE_NOTIFICATION);
    }

    const renderHeader = () => {
        return (
            <Column>
                <HeaderView primaryTitle={init.primaryTitle} />
            </Column>
        );
    }

    const renderQrCodeImage = () => {
        return (
            <Column alignmentHorizontal={"center"}>
                <img src={QrCodeImage} alt={init.secondaryTitle.title} width={"70%"} height={"fit-content"}/>
            </Column>
        );
    }

    const onDontSeeCodeClick = () => {
        props.history.push(ROUTING.CREATE_NOTIFICATION);
    }

    const renderDontSeeCodeCodeButton = () => {
        return (
            <Column width="100%" alignmentVertical="bottom">
                <Button size={"medium"} type={"link"} onClick={onDontSeeCodeClick}>
                    {getFormattedString(props, STRING_IDS.DONT_SEE_CODE)}
                </Button>
            </Column>
        )
    }

    return (
        <Column spacingInset={"medium"}>
            {renderHeader()}
            {renderQrCodeImage()}
            {renderDontSeeCodeCodeButton()}
            <ScanWithCodeView onSubmit={onQrCodeScan} focusLock={true} ariaLabel={props.ariaLabel}/>
        </Column>
    )
}

export default injectIntl(EditLocationView);