import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import {Route, Switch} from "react-router-dom";
import {getNotificationRoutingPath, ROUTING} from "../../constants/Routing";
import CreateNotificationView from "./view/CreateNotificationView";
import EditAliasViewBadgeScan from "./view/EditAliasViewBadgeScan";
import EditLocationView from "./view/EditLocationView";
import CreateNotificationContextProvider from "./context/CreateNotificationContext";
import EditAliasViewTextInput from "./view/EditAliasViewTextInput";

class CreateNotification extends Component {
    render () {
        return (
            <CreateNotificationContextProvider>
                <Column height={"100%"}>
                    <Switch>
                        <Route exact path={getNotificationRoutingPath(ROUTING.EDIT_ALIAS_BADGE_SCAN)} component={EditAliasViewBadgeScan} />
                        <Route exact path={getNotificationRoutingPath(ROUTING.EDIT_ALIAS_TEXT_INPUT)} component={EditAliasViewTextInput} />
                        <Route exact path={getNotificationRoutingPath(ROUTING.EDIT_LOCATION)} component={EditLocationView} />
                        <Route exact path={ROUTING.CREATE_NOTIFICATION} component={CreateNotificationView} />
                    </Switch>
                </Column>
            </CreateNotificationContextProvider>
        )
    }
}

export default CreateNotification;