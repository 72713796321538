import React, { useCallback,  useState, useEffect  } from 'react';
import Column from "@amzn/meridian/column";
import { css } from "emotion";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Tooltip from "@amzn/meridian/tooltip";
import Card from "@amzn/meridian/card";
import Toaster from "@amzn/meridian/toaster/toaster";
import Alert from "@amzn/meridian/alert";

import LinearGauge from "@amzn/meridian/linear-gauge";
import Button from "@amzn/meridian/button";
import { getStowMetrics, exitModule, getNumericalDisplayValueFromData } from "./NetworkUtils";
import { Loader } from "@amzn/dolphin-uiweb-framework";
import { AnalyticEventKeys, MobileAnalyticsHelper } from "@amzn/dolphin-web-framework";
import { ANALYTICS_DESCRIPTION } from "./Constants";
import { ROUTING, getInsightRoutingPath } from '../../../constants/Routing';
import { INSIGHT_STRINGS, COMMON_STRINGS, INSIGHT_DESCRIPTIONS } from "../../../constants/Strings";
import deliveryCarImg from "../../../images/delivery_car.png";
import packagesImg from "../../../images/packages.png";
import salImg from "../../../images/sal.png"

const StowSummaryView = (props) => {

    const [data, setData] = useState(null);

    const noRightMarginText = css({
        marginRight: "0px !important"
    });

    const sessionSummaryTimingText = css({
        textAlign: "right",
        color: "grey"
    });

    const moodOptions = [
        { value: "1", label: "frown", emoji: "😞" },
        { value: "2", label: "slight frown", emoji: "🙁" },
        { value: "3", label: "neutral", emoji: "😐" },
        { value: "4", label: "slight smile", emoji: "🙂" },
        { value: "5", label: "smile", emoji: "😀" }
    ];

    const [surveyResponse, setSurveyResponse] = useState();
    const [submittedSurveyResponse, setSubmittedSurveyResponse] = useState(false);

    const [toasts, setToasts] = useState([]);
    const onCloseToast = useCallback(id => setToasts(toasts.filter(t => t.id !== id)), [toasts]);
    const onOpenToast = useCallback(id => setToasts(toasts.concat({ id: id, timeout: 3000 })), [toasts]);

    useEffect(() => {
        const START_TIME = Date.now();
        getStowMetrics(setData);

        function recordMetric() {
            MobileAnalyticsHelper.processAnalytics(START_TIME, ANALYTICS_DESCRIPTION.STOW_SUMMARY_METRIC,
                null, AnalyticEventKeys.Modules.MY_INSIGHT, null, null,
                AnalyticEventKeys.Events.USER_VIEWED_STOW_SUMMARY_INSIGHT, false, null, null);
        }

        return () => {
            recordMetric();
        }
    }, []);

    const renderToaster = () => {
        return (
            <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {toast => (
                    <Alert type={"success"}>
                        {toast.id}
                    </Alert>
                )}
          </Toaster>
        )
    }

    const renderCardTitle = (title, description, image) => {
        return (
            <Column>
                <Tooltip position={"start"} title={description}>
                    <Column height={"100%"} alignmentHorizontal={"center"}>
                        <img alt={description} width={"80%"} src={image}/>
                        <Text
                            alignment={"center"}
                            type={"h100"}
                        >{title}</Text>
                    </Column>
                </Tooltip>
            </Column>
        )
    }

    
    const renderCardWithBar = (title, description, image, titleOnLeft, associateAverage, stationAverage) => {
        return (
            <Card width={"100%"} spacingInset={"200"}>
                <Column>
                    <Row
                        widths={["grid-6", "grid-6"]}
                        id={"info-icon-row"}
                    >
                        {titleOnLeft && renderCardTitle(title, description, image)}
                        <Column alignmentHorizontal={titleOnLeft? "left": "right"}>
                            <Box>
                                <Text
                                    className={noRightMarginText}
                                >
                                    {`You: ${associateAverage}`}
                                </Text>
                                <Text
                                    className={noRightMarginText}
                                >
                                    {`Station avg: ${stationAverage}`}
                                </Text>
                            </Box>
                        </Column>
                        {!titleOnLeft && renderCardTitle(title, description, image)}
                    </Row>
                    
                    <Row widths={["grid-11"]} alignmentHorizontal={"center"}>
                        <LinearGauge
                            value={associateAverage}
                            target={stationAverage}
                            minValue={0}
                            maxValue={stationAverage * 2}
                            labels={[
                                {
                                    position: {stationAverage},
                                    alignment: "center",
                                    render: "Label",
                                },
                            ]}
                        />
                    </Row>
                </Column>
            </Card>
        );
    };

    const renderStowPPH = () => {
        return renderCardWithBar(
            COMMON_STRINGS.PPH,
            INSIGHT_DESCRIPTIONS.PPH,
            deliveryCarImg,
            true,
            getNumericalDisplayValueFromData(data.associateStowMetrics.pph),
            getNumericalDisplayValueFromData(data.stationStowMetrics.pph),
        );
    };
    
    const renderPackagesStowed = () => {
        return renderCardWithBar(
            COMMON_STRINGS.STOWED_COUNT,
            INSIGHT_DESCRIPTIONS.STOWED_COUNT,
            packagesImg,
            false,
            getNumericalDisplayValueFromData(data.associateStowMetrics.stowedCount),
            getNumericalDisplayValueFromData(data.stationStowMetrics.stowedCount),
        );
    };
    
    const renderFsaf = () => {
        return renderCardWithBar(
            COMMON_STRINGS.FSAF,
            INSIGHT_DESCRIPTIONS.FSAF,
            salImg,
            true,
            getNumericalDisplayValueFromData(data.associateStowMetrics.fsaf),
            getNumericalDisplayValueFromData(data.stationStowMetrics.fsaf),
        );
    };

    const renderSessionSummaryTimingText = () => {
        const now = new Date();
        const timeText = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return <Text type={"b200"} className={sessionSummaryTimingText}>{`Your shift summary till ${timeText}`}</Text>;
    }

    const handleSubmitSurvey = (value) => {
        const START_TIME = Date.now();

        setSurveyResponse(value);

        MobileAnalyticsHelper.processAnalytics(START_TIME, ANALYTICS_DESCRIPTION.MOOD_SURVEY_RESPONSE,
            null, AnalyticEventKeys.Modules.MY_INSIGHT, null, surveyResponse,
            AnalyticEventKeys.Events.MOOD_SURVEY_RESPONSE, false, null, null);
        setSubmittedSurveyResponse(true);

        onOpenToast("Survey response saved");
    }

    const renderMoodSurvey = () => {
        return (
            <Card width={"100%"} spacingInset={"200"}>
                <Column alignmentHorizontal={"center"}>
                    <Row widths={["grid-12"]} alignmentHorizontal={"center"} width={"100%"} id={"info-icon-row"}>                          
                        <Text
                            type={"h300"}
                            alignment={"center"}
                        >
                            {INSIGHT_DESCRIPTIONS.SURVEY}
                        </Text>
                    </Row>
                    <Row width={"100%"} id={"info-icon-row"} alignmentHorizontal={"center"} spacing={"200"}>
                        {moodOptions.map((option) => (
                            <Button key={option.value} onClick={() => handleSubmitSurvey(option.value)}>
                                <span role="img" aria-label={option.label}>
                                    {option.emoji}
                                </span>
                            </Button>
                        ))}

                    </Row>
                </Column>
            </Card>
            
        )
    }

    const renderOpenMyInsights = () => {
        return (
            <Row alignmentHorizontal={"end"}>
                <Button 
                    type={"primary"} 
                    onClick={() => {
                        props.history.push(getInsightRoutingPath(ROUTING.HISTORICAL_SUMMARY))
                    }}
                >
                    {INSIGHT_STRINGS.OPEN_MY_INSIGHTS}
                </Button>
            </Row>
        )
    }


    const renderStowMetrics = () => {

        // Display nothing until data is not fetched.
        if (!data) {
            return <Loader/>;
        }

        // If the data is not fetched in correct format, then exit the module.
        if (!data.associateStowMetrics || !data.stationStowMetrics) {
            exitModule()
        }

        return (
            <Column width={"100%"} height={"100%"} spacingInset={"small"} alignmentVertical={"start"}>
                {renderToaster()}
                {renderSessionSummaryTimingText()}
                {renderStowPPH()}
                {renderPackagesStowed()}
                {renderFsaf()}
                {!submittedSurveyResponse && renderMoodSurvey()}
                {renderOpenMyInsights()}
            </Column>
        )
    }

    return (
        renderStowMetrics()
    )
}

export default StowSummaryView;