import React, {useContext} from "react"
import {NotificationContext} from "../context/NotificationContext";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import {isNonEmpty} from "../../utils/Utils";

const NotificationView = () => {

    const {notificationModel, notificationActions: {clearNotification}} = useContext(NotificationContext);

    const renderNotification = () => {
        if (isNonEmpty(notificationModel.message)) {
            return (
                <Column width="100%" spacingInset="small">
                    <Alert type={notificationModel.type} size="medium" onClose={clearNotification}>
                        {notificationModel.message}
                    </Alert>
                </Column>
            )
        }

        return null;
    }

    return renderNotification();
}

export default NotificationView;
