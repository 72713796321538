import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {COMMON_STRINGS} from "../../../constants/Strings";
import {fetchNotificationsNetwork} from "../network/ListNotificationNetwork";

export const DISPLAY_NOTIFICATION = "DISPLAY_NOTIFICATION";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export const fetchNotifications = (dispatch, alias) => {
    // Get the listNotificationData.
    fetchNotificationsNetwork(alias).then(response => {
        if (response.status === 200) {
            // read the data.
            if (response.data) {
                const listNotificationData = response.data.notifications;
                if (listNotificationData) {
                    dispatch({type: DISPLAY_NOTIFICATION, data: listNotificationData});
                }
                else {
                    dispatch({type: DISPLAY_NOTIFICATION, data: []});
                }
                updateNotifications(listNotificationData);
            }
        }
    }).catch(error => {
        console.error(error);
    });
}

const updateNotifications = (listNotificationData) => {
    listNotificationData.forEach((notificationData) => {
        // Update the notification status only if CTA does not exist.
        if (!notificationData.pwaModuleName) {
            NativeMeshInteractor.updateNotificationStatus(notificationData.notificationId, COMMON_STRINGS.DELIVERED, notificationData.priority);
        }
    })
}