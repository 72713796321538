export const NOTIFICATION_SERVICE = {
    NA: {
        BETA: "https://jzaznc53pa.execute-api.us-east-1.amazonaws.com/beta",
        GAMMA: "https://zy5ifkuut9.execute-api.us-east-1.amazonaws.com/gamma",
        PROD: "https://f3ox6yynw6.execute-api.us-east-1.amazonaws.com/prod",
    },
    EU: {
        BETA: "https://jzaznc53pa.execute-api.us-east-1.amazonaws.com/beta",
        GAMMA: "https://zy5ifkuut9.execute-api.us-east-1.amazonaws.com/gamma",
        PROD: "https://6gc3ea4vng.execute-api.eu-west-1.amazonaws.com/prod",
    },
    FE: {
        BETA: "https://jzaznc53pa.execute-api.us-east-1.amazonaws.com/beta",
        GAMMA: "https://zy5ifkuut9.execute-api.us-east-1.amazonaws.com/gamma",
        PROD: "https://346bibf2lc.execute-api.us-west-2.amazonaws.com/prod",
    },
}

export const API_PATH = {
    GET_NOTIFICATION: "/getNotification",
    GET_USERNAME_FOR_BARCODE: "/getUsernameForBarcodeId",
    CREATE_HELP_REQUEST: "/createHelpRequest"
}

export const DOLPHIN_AUTH_SERVICE = {
    NA: {
        BETA: "https://na-integ-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        GAMMA: "https://na-master-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        PROD: "https://na-prod-fallbacklogin.dolphinprod.last-mile.amazon.dev",
    },
    EU: {
        BETA: "https://na-integ-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        GAMMA: "https://eu-master-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        PROD: "https://eu-prod-fallbacklogin.dolphinprod.last-mile.amazon.dev",
    },
    FE: {
        BETA: "https://na-integ-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        GAMMA: "https://fe-master-fallbacklogin.dolphinprod.last-mile.amazon.dev",
        PROD: "https://fe-prod-fallbacklogin.dolphinprod.last-mile.amazon.dev",
    },
}

export const DOLPHIN_AUTH_API_KEY = {
    NA: {
        BETA: "DolphinLoginLessLambdabeta-us-east-1",
        GAMMA: "DolphinLoginLessLambdagamma-us-east-1",
        PROD: "DolphinLoginLessLambdaprod-us-east-1",
    },
    EU: {
        BETA: "DolphinLoginLessLambdabeta-us-east-1",
        GAMMA: "DolphinLoginLessLambdagamma-eu-west-1",
        PROD: "DolphinLoginLessLambdaprod-eu-west-1",
    },
    FE: {
        BETA: "DolphinLoginLessLambdabeta-us-east-1",
        GAMMA: "DolphinLoginLessLambdagamma-us-west-2",
        PROD: "DolphinLoginLessLambdaprod-us-west-2",
    },
}