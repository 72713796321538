import React, {createContext, useReducer} from "react";
import {ListNotificationReducer} from "../reducer/ListNotificationReducer";

export const ListNotificationContext = createContext();

const model = {
    isLoading: true,
    data: []
}

const ListNotificationContextProvider = (props) => {
    const [listNotificationModel, listNotificationDispatch] = useReducer(ListNotificationReducer, model);
    return (
        <ListNotificationContext.Provider value={{listNotificationModel, listNotificationDispatch}}>
            {props.children}
        </ListNotificationContext.Provider>
    )
}

export default ListNotificationContextProvider;